import React, { Component } from "react";
import ReactCodeInput from "react-code-input";
import { KEY_PAIR_CODE, KEY_PAIRED } from "./data/localstorage";
import { PAIR_CODE_LENGTH } from "./PairCode";
import PubSub from "@aws-amplify/pubsub/lib";
import { FullHeightFlex } from "./InputLogin";
import { Text, Image } from "rebass";

class InputPairCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pairing: false
    };
  }

  onChange = pairCode => {
    if (pairCode.length === PAIR_CODE_LENGTH) {
      this.setState({ pairing: true });

      if (this.sub) {
        this.sub.unsubscribe();
      }
      this.sub = PubSub.subscribe(`pair-${pairCode}-ack`).subscribe({
        next: data => {
          // Pair was successful.
          this.setState({ pairing: false });
          localStorage.setItem(KEY_PAIRED, true);
          localStorage.setItem(KEY_PAIR_CODE, pairCode);
          this.props.history.push("/");
        }
      });

      PubSub.publish(`pair-${pairCode}`, { msg: "pair" });
    }
  };

  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  render() {
    return (
      <FullHeightFlex
        flexDirection="column"
        alignItems="center"
        justifyContent="start"
        pt="15vh"
      >
        <Image src="/hangtime-logo.png" width={0.8} mx={5} mb={4} />
        <Text fontSize={3} my={3}>
          Enter pair code:
        </Text>
        <ReactCodeInput
          type="number"
          fields={PAIR_CODE_LENGTH}
          onChange={this.onChange}
          disabled={this.state.pairing}
        />
      </FullHeightFlex>
    );
  }
}

export default InputPairCode;
