import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const WaitingRoomBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default class WaitingRoom extends Component {
  getListOfActiveUsers = streams => {
    const names = streams.map(stream => {
      return JSON.parse(stream.connection.data).name;
    });
    return Array.from(new Set(names));
  };
  render() {
    const activeUsers = this.getListOfActiveUsers(this.context.streams);
    return (
      <WaitingRoomBox>
        <div>
          Your room is currently{" "}
          {activeUsers.length > 0 ? "active" : "inactive"}.
        </div>
        <ul>
          {activeUsers.map(name => (
            <li key={name}>{name}</li>
          ))}
        </ul>
      </WaitingRoomBox>
    );
  }
}

WaitingRoom.contextTypes = {
  session: PropTypes.shape({
    publish: PropTypes.func,
    subscribe: PropTypes.func
  }),
  streams: PropTypes.arrayOf(PropTypes.object)
};
