import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import InputLogin from "./InputLogin";
import InputHome from "./InputHome";
import OutputHome from "./OutputHome";
import InputPairCode from "./InputPairCode";
import OutputPairCode from "./OutputPairCode";

class App extends Component {
  isMobileDevice() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  isOutputDevice() {
    // TODO: Make this more sophisticated. For now, any non-mobile device
    // is taken to be an output device (e.g. TV or laptop).
    return !this.isMobileDevice();
  }

  render() {
    return <HashRouter>{this.mainContent()}</HashRouter>;
  }

  mainContent() {
    if (this.isOutputDevice()) {
      return (
        <Switch>
          <Route exact path="/" component={OutputHome} />
          <Route path="/paircode" component={OutputPairCode} />
        </Switch>
      );
    } else {
      // Is input device.
      return (
        <Switch>
          <Route exact path="/" component={InputHome} />
          <Route path="/login" component={InputLogin} />
          <Route path="/pair" component={InputPairCode} />
        </Switch>
      );
    }
  }
}

export default App;
