import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  KEY_PAIRED,
  KEY_PAIR_CODE,
  KEY_NAME,
  KEY_LAST_JOIN_TIME,
  KEY_PHONE
} from "./data/localstorage";
import Session, { withSession } from "./Session";
import "./InputHome.css";
import { OTPublisher, OTSubscriber } from "opentok-react";
import styled from "styled-components";
import WaitingRoom from "./WaitingRoom";
import { Box, Flex, Text } from "rebass";
import update from "immutability-helper";
import {
  FiMic,
  FiMicOff,
  FiVideoOff,
  FiVideo,
  FiTv,
  FiLogOut
} from "react-icons/fi";
import PubSub from "@aws-amplify/pubsub/lib";
import queryString from "query-string";
import phone from "phone";

const templateProperties = {
  insertMode: "append",
  width: "100%",
  height: "100%",
  showControls: false,
  style: {
    buttonDisplayMode: "off"
  }
};

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Main = styled.div`
  flex-grow: 1;
  width: 100%;
  position: relative;
`;

const JoinButton = styled.div`
  color: white;
  background-color: green;
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
`;

const InRoomControls = styled(Flex)`
  background-color: black;
  height: 100px;
  align-items: stretch;
  justify-content: center;
`;

class InRoomControlButton extends Component {
  components = {
    mic: FiMic,
    micOff: FiMicOff,
    video: FiVideo,
    videoOff: FiVideoOff,
    videoTv: FiTv,
    leave: FiLogOut
  };

  render() {
    const { icon, children, ...others } = this.props;
    const IconTag = this.components[icon];
    return (
      <Flex
        flex="1"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        {...others}
      >
        <Box pb="3">{IconTag && <IconTag color="white" size="30px" />}</Box>
        <Text color="white">{this.props.children}</Text>
      </Flex>
    );
  }
}

const VideoState = {
  OFF: 0,
  TV: 1,
  ON: 2
};

class InputHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      audioEnabled: true,
      videoState: VideoState.ON
    };
  }

  isLoggedIn() {
    const pn = localStorage.getItem(KEY_PHONE);
    if (phone(pn).length < 1) {
      // Non-valid phone number.
      localStorage.removeItem(KEY_PHONE);
      return false;
    }
    return localStorage.getItem(KEY_NAME);
  }

  isPaired() {
    return localStorage.getItem(KEY_PAIRED);
  }

  join = () => {
    this.setState({
      active: true,
      audioEnabled: true,
      videoState: VideoState.ON
    });

    this.notifyOthersOnJoin();
  };

  leave = () => {
    this.setState({ active: false });
  };

  notifyOthersOnJoin = () => {
    /**
     * Minimum # of milliseconds since the last notification for
     * the new join to notify others. This is to make sure that we don't spam
     * SMS to people on repeated leave/joins.
     */
    const NOTIFY_OTHERS_ON_JOIN_MIN_DUR = 60 * 60 * 1000; // 1 hour.

    const lastJoinTime = Number(localStorage.getItem(KEY_LAST_JOIN_TIME));
    if (
      lastJoinTime &&
      Date.now() - lastJoinTime < NOTIFY_OTHERS_ON_JOIN_MIN_DUR
    ) {
      return;
    }

    this.sendSms();
    localStorage.setItem(KEY_LAST_JOIN_TIME, Date.now());
  };

  enableSms = () => {
    // Disable this in non-production builds unless flag is specified.
    const isProduction =
      process.env.NODE_ENV === "production" &&
      !window.location.host.startsWith("dev");
    return (
      isProduction ||
      "enableSms" in queryString.parse(this.props.location.search)
    );
  };

  sendSms = () => {
    if (!this.enableSms()) {
      console.log(
        "In dev mode and not sending SMS. Add ?enableSms to actually send."
      );
      return;
    }

    const data = {
      phone: phone(localStorage.getItem(KEY_PHONE))[0],
      name: localStorage.getItem(KEY_NAME)
    };
    fetch("https://presence.jomolabs.co/hangtime/api/v1/sms", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error(response.msg || "Request failed");
        }
        return response.json();
      })
      .then(response => {
        console.log("Sent sms successfully!");
      });
  };

  toggleAudio = () => {
    this.setState(prevState => {
      return { audioEnabled: !prevState.audioEnabled };
    });
  };

  toggleVideo = () => {
    this.setState(prevState => {
      const newVideoState =
        (prevState.videoState + 1) % Object.keys(VideoState).length;
      const pairCode = localStorage.getItem(KEY_PAIR_CODE);

      // Notify TV to enable or disable video.
      if (newVideoState === VideoState.TV) {
        PubSub.publish(`action-${pairCode}`, { msg: "video-on" });
      } else if (prevState.videoState === VideoState.TV) {
        PubSub.publish(`action-${pairCode}`, { msg: "video-off" });
      }

      return {
        videoState: newVideoState
      };
    });
  };

  /**
   * Use outputVolume flag to test echo cancellation when we output
   * the subscriber at a low volume. Should be an integer between 0-100.
   */
  maybeRenderAudioOnlySubscriptions = () => {
    const outputVolume = queryString.parse(this.props.location.search)[
      "outputVolume"
    ];
    if (outputVolume) {
      const audioSubProperties = {
        insertMode: "append",
        width: "0",
        height: "0",
        showControls: false,
        subscribeToVideo: false,
        audioVolume: Number(outputVolume),
        style: {
          buttonDisplayMode: "off",
          nameDisplayMode: "off"
        }
      };
      return withSession(props => {
        const { session, streams } = props;
        return (
          <Box style={{ display: "none" }}>
            {streams.map(stream => {
              return (
                <OTSubscriber
                  key={stream.id}
                  properties={audioSubProperties}
                  stream={stream}
                  session={session}
                />
              );
            })}
          </Box>
        );
      });
    } else {
      return null;
    }
  };

  render() {
    if (!this.isLoggedIn()) {
      return <Redirect to="/login" />;
    }

    if (!this.isPaired()) {
      return <Redirect to="/pair" />;
    }

    const { active, audioEnabled, videoState } = this.state;
    const publishProperties = update(templateProperties, {
      publishAudio: { $set: audioEnabled },
      publishVideo: { $set: videoState === VideoState.ON },
      name: { $set: localStorage.getItem(KEY_NAME) }
    });

    let AudioStreams = this.maybeRenderAudioOnlySubscriptions();

    return (
      <Session isMobile={true}>
        <Container>
          <Main>
            {!active && <WaitingRoom />}
            {active && <OTPublisher properties={publishProperties} />}
            {active && AudioStreams && <AudioStreams />}
          </Main>
          {!active && <JoinButton onClick={this.join}>Join room</JoinButton>}
          {active && (
            <InRoomControls>
              <InRoomControlButton
                onClick={this.toggleAudio}
                icon={audioEnabled ? "mic" : "micOff"}
              >
                Mic
              </InRoomControlButton>
              <InRoomControlButton
                onClick={this.toggleVideo}
                icon={
                  videoState === VideoState.OFF
                    ? "videoOff"
                    : videoState === VideoState.ON
                    ? "video"
                    : "videoTv"
                }
              >
                Camera
              </InRoomControlButton>
              <InRoomControlButton onClick={this.leave} icon="leave">
                Leave
              </InRoomControlButton>
            </InRoomControls>
          )}
        </Container>
      </Session>
    );
  }
}

export default InputHome;
