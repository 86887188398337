/**
 * Track whether this output device is paired.
 * TODO: Use actual user credentials once server is up.
 */
export const KEY_PAIRED = "paired";

/**
 * In the case of output device, saves the generated pair code for reuse.
 * For input devices, saves the code it paired with.
 */
export const KEY_PAIR_CODE = "pairCode";

/**
 * Name of the user.
 */
export const KEY_NAME = "name";

/**
 * Phone number of the user.
 */
export const KEY_PHONE = "phone";

/**
 * Timestamp of the last join.
 */
export const KEY_LAST_JOIN_TIME = "lastJoinTime";
