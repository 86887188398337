import React, { Component } from "react";
import credentials from "./credentials.json";
import queryString from "query-string";
import { OTSession } from "opentok-react";
import { KEY_PAIR_CODE, KEY_NAME } from "./data/localstorage";
import PropTypes from "prop-types";

class Session extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null
    };
  }

  componentDidMount() {
    this.fetchToken();
  }

  fetchToken = () => {
    const queryData = {
      sessionId: credentials.sessionId,
      name: localStorage.getItem(KEY_NAME),
      pairCode: localStorage.getItem(KEY_PAIR_CODE),
      isMobile: this.props.isMobile
    };
    Object.assign(queryData, this.props.data);
    fetch(
      "https://presence.jomolabs.co/hangtime/api/v1/generateToken?" +
        queryString.stringify(queryData)
    )
      .then(response => {
        return response.json();
      })
      .then(response => {
        const token = response.token;
        console.log("Received token: " + token);
        this.setState({ token: token });
      });
  };

  render() {
    if (!this.state.token) {
      return null;
    }

    return (
      <OTSession
        apiKey={credentials.apiKey}
        sessionId={credentials.sessionId}
        token={this.state.token}
      >
        {React.Children.toArray(this.props.children).filter(c => c)}
      </OTSession>
    );
  }
}

export default Session;

export function withSession(WrappedComponent) {
  const WrapperComponent = class extends React.Component {
    render() {
      const { session, streams } = this.context;
      return (
        <WrappedComponent session={session} streams={streams} {...this.props} />
      );
    }
  };

  WrapperComponent.contextTypes = {
    session: PropTypes.shape({
      publish: PropTypes.func,
      subscribe: PropTypes.func
    }),
    streams: PropTypes.arrayOf(PropTypes.object)
  };

  return WrapperComponent;
}
