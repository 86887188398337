// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_content_delivery_bucket": "hangtime-demo-20190402111537-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "http://hangtime-demo-20190402111537-hostingbucket-dev.s3-website-us-west-2.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-west-2:990f0313-a3a8-40f6-9821-2cb6458f5376",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_xCkytvJp9",
    "aws_user_pools_web_client_id": "2ge00acfpu6pqsdo919d6qq5c7",
    "oauth": {}
};


export default awsmobile;
