import styled from "styled-components";
import {
  position,
  zIndex,
  top,
  right,
  bottom,
  left,
  borderRadius,
  border,
  borderColor
} from "styled-system";
import { Box } from "rebass";

// Borders

export const BorderedBox = styled(Box)(border, borderColor, borderRadius);

// Position

export const Position = styled(BorderedBox)(
  position,
  zIndex,
  top,
  right,
  bottom,
  left
);

export const Relative = styled(Position)([]);

Relative.defaultProps = {
  position: "relative"
};

export const Absolute = styled(Position)([]);

Absolute.defaultProps = {
  position: "absolute"
};

export const Fixed = styled(Position)([]);

Fixed.defaultProps = {
  position: "fixed"
};

export const Sticky = styled(Position)([]);

Sticky.defaultProps = {
  position: "sticky"
};
