import React, { Component } from "react";
import { Text } from "rebass";
import PubSub from "@aws-amplify/pubsub/lib";
import { KEY_PAIRED, KEY_PAIR_CODE } from "./data/localstorage";

export const PAIR_CODE_LENGTH = 4;

class PairCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pairCode: localStorage.getItem(KEY_PAIR_CODE)
    };
    if (!this.state.pairCode) {
      let code = this.generateCode();
      localStorage.setItem(KEY_PAIR_CODE, code);
      this.state.pairCode = code;
    }
  }

  componentDidMount() {
    this.listenForPairing();
  }

  componentWillUnmount() {
    if (this.pairSub) {
      this.pairSub.unsubscribe();
    }
  }

  listenForPairing() {
    const { pairCode } = this.state;
    this.pairSub = PubSub.subscribe(`pair-${pairCode}`).subscribe({
      next: data => {
        console.log("Pairing request received with data: " + data);
        localStorage.setItem(KEY_PAIRED, true);
        localStorage.setItem(KEY_PAIR_CODE, pairCode);
        PubSub.publish(`pair-${pairCode}-ack`, { msg: "pairack" });
        this.props.onPair();
      }
    });
  }

  generateCode() {
    const code = [...Array(PAIR_CODE_LENGTH)].map((_, index) => {
      return Math.floor(Math.random() * 10);
    });
    return code.join("");
  }

  render() {
    const { onPair, ...others } = this.props;
    return <Text {...others}>{this.state.pairCode}</Text>;
  }
}

export default PairCode;
