import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

// Set up aws pubsub.
import Auth from "@aws-amplify/auth/lib";
import Amplify from "@aws-amplify/core/lib";
import awsmobile from "./aws-exports";
import PubSub from "@aws-amplify/pubsub/lib";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
Amplify.configure(awsmobile);
PubSub.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "us-west-2",
    aws_pubsub_endpoint:
      "wss://adncdyurh8v4t-ats.iot.us-west-2.amazonaws.com/mqtt"
  })
);

ReactDOM.render(<App />, document.getElementById("root"));
