import React, { Component } from "react";
import { Flex, Image, Text } from "rebass";
import styled from "styled-components";
import PairCode from "./PairCode";

const FullHeightFlex = styled(Flex)`
  height: 100vh;
`;

class OutputPairCode extends Component {
  onPair = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <FullHeightFlex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Image src="/hangtime-logo.png" width={0.8} mx={5} mb={4} />
        <Text fontSize={3}>Connect your phone by entering this code.</Text>
        <PairCode onPair={this.onPair} fontSize={8} fontWeight="bold" my={3} />
      </FullHeightFlex>
    );
  }
}

export default OutputPairCode;
