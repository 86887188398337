import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { KEY_PAIRED, KEY_PAIR_CODE } from "./data/localstorage";
import { OTSubscriber, OTPublisher } from "opentok-react";
import Session, { withSession } from "./Session";
import styled from "styled-components";
import update from "immutability-helper";
import "./OutputHome.css";
import PairCode from "./PairCode";
import { Box, Flex } from "rebass";
import { Absolute } from "./shared/components";
import PubSub from "@aws-amplify/pubsub/lib";

const subscribeProperties = {
  insertMode: "append",
  width: "100%",
  height: "100%",
  showControls: true,
  style: {
    buttonDisplayMode: "off",
    nameDisplayMode: "on"
  }
};

const selfProperties = {
  width: "100%",
  height: "100%",
  showControls: false,
  style: {
    buttonDisplayMode: "off"
  },
  publishAudio: false
};

const Ready = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 100vh;
  text-align: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.cols}, 1fr);
  position: absolute;
  width: 100%;
  height: 100%;

  > div {
    position: relative;
  }
`;

const SelfView = styled(Absolute)`
  bottom: 0;
  right: 0;
  width: 30vw;
  height: 30vw;
  max-width: 300px;
  border: 2px solid white;
`;

const StatusMessage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 5vw;
  align-items: center;
  justify-content: center;
`;

class StreamGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoEnabled: false
    };
  }

  componentDidMount() {
    const pairCode = localStorage.getItem(KEY_PAIR_CODE);
    this.pairActionSub = PubSub.subscribe(`action-${pairCode}`).subscribe({
      next: data => {
        console.log("Received pair action with msg: " + data.value.msg);
        if (data.value.msg === "video-on") {
          this.setState({ videoEnabled: true });
        } else if (data.value.msg === "video-off") {
          this.setState({ videoEnabled: false });
        }
      }
    });
  }

  componentDidUpdate() {
    if (
      this.state.videoEnabled &&
      !this.props.streams.some(this.isPairedStream)
    ) {
      // If paired stream is no longer present, make sure to disable video.
      this.setState({ videoEnabled: false });
    }
  }

  componentWillUnmount() {
    if (this.pairActionSub) {
      this.pairActionSub.unsubscribe();
    }
  }

  isPairedStream = stream => {
    const data = JSON.parse(stream.connection.data);
    return data.pairCode === this.props.pairCode;
  };

  render() {
    const { session, streams } = this.props;

    if (streams.length === 0) {
      return <StatusMessage>No one here!</StatusMessage>;
    } else if (!streams.some(this.isPairedStream)) {
      return <StatusMessage>Join on your phone!</StatusMessage>;
    }

    let selfViewStream;
    if (this.state.videoEnabled) {
      selfViewStream = <OTPublisher properties={selfProperties} />;
    } else {
      const properties = update(selfProperties, {
        // Do not subscribe to audio from a paired stream.
        subscribeToAudio: { $set: false }
      });
      selfViewStream = (
        <OTSubscriber
          stream={streams.find(this.isPairedStream)}
          properties={properties}
          session={session}
        />
      );
    }

    let countByPairCode = {};
    streams.forEach(stream => {
      const data = JSON.parse(stream.connection.data);
      countByPairCode[data.pairCode] = countByPairCode[data.pairCode]
        ? countByPairCode[data.pairCode] + 1
        : 1;
    });

    let visibleStreams = [];
    let invisibleStreams = [];

    streams.forEach(stream => {
      if (this.isPairedStream(stream)) {
        // If this is a paired stream, ignore.
        return;
      }
      const data = JSON.parse(stream.connection.data);
      if (countByPairCode[data.pairCode] > 1 && !stream.hasVideo) {
        // If there are multiple streams for this pair and this one doesn't have video, make it invisible.
        invisibleStreams.push(stream);
      } else {
        visibleStreams.push(stream);
      }
    });

    const cols = Math.ceil(Math.sqrt(visibleStreams.length));
    return (
      <Box>
        <Grid cols={cols}>
          {visibleStreams.map(stream => {
            return (
              <OTSubscriber
                key={stream.id}
                properties={this.props.properties}
                stream={stream}
                session={session}
              />
            );
          })}
        </Grid>
        <div style={{ display: "none" }}>
          {invisibleStreams.map(stream => {
            return (
              <OTSubscriber key={stream.id} stream={stream} session={session} />
            );
          })}
        </div>
        <SelfView mb={3} mr={3}>
          {selfViewStream}
        </SelfView>
      </Box>
    );
  }
}

const StreamGridWithSession = withSession(StreamGrid);

class OutputHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false
    };
  }

  isPaired() {
    return localStorage.getItem(KEY_PAIRED);
  }

  onReadyClick = () => {
    this.setState({ ready: true });
  };

  onPair = () => {
    this.props.history.push("/");
  };

  render() {
    if (!this.isPaired()) {
      return <Redirect to="/paircode" />;
    }

    if (!this.state.ready) {
      // Force a click at first, since otherwise AudioContext may not be able to get started.
      // This is due to autoplay limitations on popular browsers.
      // See https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
      return (
        <Ready onClick={this.onReadyClick}>
          Click anywhere to get started.
        </Ready>
      );
    }

    return (
      <Flex>
        <Session isMobile={false}>
          <StreamGridWithSession
            properties={subscribeProperties}
            pairCode={localStorage.getItem(KEY_PAIR_CODE)}
          />
        </Session>
        <Absolute
          top={0}
          right={0}
          m={2}
          border="2px solid black"
          borderRadius={3}
          p={2}
        >
          <PairCode fontSize="5vh" onPair={this.onPair} />
        </Absolute>
      </Flex>
    );
  }
}

export default OutputHome;
